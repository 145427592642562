<template>
  <router-view />
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'App',

    mounted() {
      this.initFournisseurRtc()
    },

    methods: {
      ...mapActions(['initFournisseurRtc']),
    },
  }
</script>
