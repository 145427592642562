import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/i18n.js'
import store from './store.js'

import restApiService from '@/services/restApiService.js'
import notificationSonore from '@/services/notificationSonore.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/public/gabarit/Gabarit'),
      children: [
        {
          meta: { public: true },
          name: 'Connexion',
          path: '/',
          component: () => import('@/views/public/Connexion'),
        },
      ],
    },
    {
      meta: { public: true },
      path: '/test-ws',
      component: () => import('@/views/public/TestWs'),
    },
    {
      path: '/sondage',
      component: () => import('@/views/app/gabarit/Gabarit'),
      children: [
        {
          name: i18n.t('sondages_page.sondages'),
          path: '/sondage/gestionSondages',
          component: () => import('@/views/app/sondage/GestionSondages'),
        },
        {
          name: i18n.t('sondage.sondage'),
          path: '/sondage/editionSondage/:idSondage',
          component: () => import('@/views/app/sondage/EditionSondage'),
        },
      ],
    },
    {
      path: '/gestion',
      component: () => import('@/views/app/gabarit/Gabarit'),
      children: [
        {
          name: i18n.t('gestion_conference.titre'),
          path: '/gestionConference/:idConference',
          component: () => import('@/views/app/GestionConference'),
        },
        {
          name: i18n.t('gestion_des_evenements.mes_evenements'),
          path: '/gestion/gestionEvenements',
          component: () => import('@/views/app/gestion/GestionEvenements'),
        },
        {
          name: i18n.t('gestion_des_evenements.edition_evenement.evenement'),
          path: '/gestion/editionEvenement/:idEvenement',
          component: () => import('@/views/app/gestion/EditionEvenement'),
        },
        {
          name: i18n.t('conference.conference'),
          path: '/gestion/editionConference/:idConference',
          component: () => import('@/views/app/gestion/EditionConference'),
        },
        {
          name: i18n.t('conference.atelier'),
          path: '/gestion/editionAtelier/:idAtelier',
          component: () => import('@/views/app/gestion/EditionAtelier'),
        },
        {
          name: i18n.t('menu.gestion_des_kiosques'),
          path: '/gestion/gestionKiosques',
          component: () => import('@/views/app/gestion/GestionKiosques'),
        },
        {
          name: i18n.t('kiosque.kiosque'),
          path: '/gestion/editionKiosque/:idKiosque',
          component: () => import('@/views/app/gestion/EditionKiosque'),
        },
      ],
    },
    {
      path: '/admin',
      meta: { dronz: 'funk' },
      component: () => import('@/views/app/gabarit/Gabarit'),
      children: [
        {
          name: 'Hack',
          path: '/admin/hack',
          component: () => import('@/views/app/admin/Hack'),
        },
        {
          name: i18n.t('menu.salons'),
          path: '/admin/salons',
          component: () => import('@/views/app/admin/Salons'),
        },
        {
          name: 'Présences',
          path: '/admin/presences',
          component: () => import('@/views/app/admin/Presences'),
        },
        {
          name: i18n.t('menu.importation'),
          path: '/admin/importation',
          component: () => import('@/views/app/admin/Importation'),
        },
        {
          name: i18n.t('menu.configuration_rtc'),
          path: '/admin/configuration-rtc',
          component: () => import('@/views/app/admin/ConfigurationRtc'),
        },
        {
          name: i18n.t('menu.usagers'),
          path: '/admin/gestionUsagers',
          component: () => import('@/views/app/admin/GestionUsagers'),
        },
        {
          name: i18n.t('menu.profil'),
          path: '/admin/editionUsager/:idUsager',
          component: () => import('@/views/app/admin/EditionUsager'),
        },
        {
          name: i18n.t('menu.comptes_clients'),
          path: '/admin/gestionComptesClients',
          component: () => import('@/views/app/admin/GestionComptesClients'),
        },
        {
          name: i18n.t('compteClient.compte_client'),
          path: '/admin/editionCompteClient/:idCompteClient',
          component: () => import('@/views/app/admin/EditionCompteClient'),
        },
      ],
    },
    {
      path: '/orka/',
      component: () => import('@/views/app/gabarit/Gabarit'),
      children: [
        {
          name: i18n.t('menu.salle_accueil'),
          path: '/orka/salleaccueil',
          component: () => import('@/views/app/SalleAccueil'),
        },
        {
          name: i18n.t('menu.deconnexion'),
          path: '/orka/deconnexion',
          component: () => import('@/views/app/Deconnexion'),
        },
        {
          name: i18n.t('presentation.salle_des_présentations'),
          path: '/orka/eve/:idEvenement/sallepresentations',
          component: () => import('@/views/app/SallePresentations'),
        },
        {
          name: i18n.t('conferencier.les_conférenciers'),
          path: '/orka/eve/:idEvenement/conferencier',
          component: () => import('@/views/app/Conferencier'),
        },
        {
          name: i18n.t('evenement.programme'),
          path: '/orka/eve/:idEvenement/programme',
          component: () => import('@/views/app/Programme'),
        },
        {
          name: i18n.t('general.aide'),
          path: '/orka/eve/:idEvenement/aideevenement',
          component: () => import('@/views/app/AideEvenement'),
        },

        {
          name: i18n.t('exposant.salon_des_exposants'),
          path: '/orka/eve/:idEvenement/sallekio',
          component: () => import('@/views/app/SalleKio'),
        },
        {
          name: 'Salle sociale',
          path: '/orka/sallesoc',
          component: () => import('@/views/app/SalleSoc'),
        },
        {
          meta: { withMedia: 'sallePrivee' },
          name: i18n.t('salle_p.salle_privee'),
          path: '/orka/sallepri/:salon',
          component: () => import('@/views/app/SallePri'),
        },
        {
          name: i18n.t('diffusion.visionnement'),
          path: '/orka/vision/:idConference',
          component: () => import('@/views/app/Vision'),
        },
        {
          name: 'Présentation',
          path: '/orka/presentation/:idConference',
          component: () => import('@/views/app/Presentation'),
        },
        {
          meta: { withMedia: 'kiosque' },
          name: i18n.t('kiosque.kiosque'),
          path: '/orka/kiosque/:idKiosque',
          component: () => import('@/views/app/Kiosque'),
        },
        {
          name: i18n.t('kiosque.renseignements'),
          path: '/orka/kiosque/edition/:idKiosque',
          component: () => import('@/views/app/EditionExposantKiosque'),
        },
        {
          name: i18n.t('profil.mon_profile'),
          path: '/orka/profil',
          component: () => import('@/views/app/Profil'),
        },
        {
          name: i18n.t('sondages_page.mes_sondages'),
          path: '/orka/mesSondages',
          component: () => import('@/views/app/MesSondages'),
        },
        {
          meta: { withMedia: 'atelier' },
          name: i18n.t('conference.atelier'),
          path: '/orka/salleatelier/:idAtelier',
          component: () => import('@/views/app/Atelier'),
        },

      ],
    },
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'demo',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl'),
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline'),
        },
        // Components
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons'),
        },
        {
          name: 'Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid'),
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Forms
        {
          name: 'Regular Forms',
          path: 'forms/regular',
          component: () => import('@/views/dashboard/forms/RegularForms'),
        },
        {
          name: 'Extended Forms',
          path: 'forms/extended',
          component: () => import('@/views/dashboard/forms/ExtendedForms'),
        },
        {
          name: 'Validation Forms',
          path: 'forms/validation',
          component: () => import('@/views/dashboard/forms/ValidationForms'),
        },
        {
          name: 'Wizard',
          path: 'forms/wizard',
          component: () => import('@/views/dashboard/forms/Wizard'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        {
          name: 'Extended Tables',
          path: 'tables/extended-tables',
          component: () => import('@/views/dashboard/tables/ExtendedTables'),
        },
        {
          name: 'Data Tabels',
          path: 'tables/data-tables',
          component: () => import('@/views/dashboard/tables/DataTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'Full Screen Map',
          path: 'maps/full-screen-map',
          component: () => import('@/views/dashboard/maps/FullScreenMap'),
        },
        // Root level
        {
          name: 'Widgets',
          path: 'widgets',
          component: () => import('@/views/dashboard/Widgets'),
        },
        {
          name: 'Charts',
          path: 'charts',
          component: () => import('@/views/dashboard/Charts'),
        },
        {
          name: 'Calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
      ],
    },
    {
      meta: { public: true },
      path: '/auth',
      component: () => import('@/views/public/Auth'),
    },
    {
      path: '*',
      redirect: { path: '/' },
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },

  ],
})

router.beforeEach((to, from, next) => {
  // Permet de jouer les notifications sonores sans interactions utilisateur sur safari
  notificationSonore.initialiser()
  // Si nous ne sommes pas dans une route avec media, il y aura nécessairement interaction.
  if (!to.meta.withMedia) {
    store.commit('setInteractionUsager', true)
  }
  if (!to.meta.public) {
    restApiService
      .get('/api/webUser/contrôlerAccès')
      .then((result) => {
        next()
      })
      .catch((erreur) => {
        const redirectPath = window.location.href
        sessionStorage.setItem('redirectPath', redirectPath)
        next({ path: '/' })
      })
  } else {
    next()
  }
})

export default router
