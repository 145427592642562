import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from './router'
import restApiService from '@/services/restApiService.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    evenementActif: null,
    userInfo: null,
    jwttoken: null,
    compteClient: undefined,
    kiosque: undefined,
    appLanguage: localStorage.getItem('appLanguage') || process.env.VUE_APP_I18N_LOCALE || 'fr',
    interactionUsager: false,
    fournisseurRtc: null,
  },
  plugins: [createPersistedState(
    {
      paths: ['jwttoken', 'evenementActif', 'userInfo', 'compteClient', 'kiosque'],
    },
  )],
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      console.log('store SET_DRAWER :', payload)
      state.drawer = payload
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload
    },
    SET_EVENEMENT_ACTIF(state, eve) {
      state.evenementActif = eve
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload
    },
    SET_COMPTECLIENT(state, payload) {
      state.compteClient = payload
    },
    SET_JWTTOKEN(state, payload) {
      state.jwttoken = payload
    },
    SET_KIOSQUE(state, payload) {
      state.kiosque = payload
    },
    SET_APP_LANGUAGE(state, language) {
      state.appLanguage = language
      localStorage.setItem('appLanguage', language)
      router.go(0)
    },

    setInteractionUsager(state, aInteragi) {
      // Une fois que l'usager a intéragi avec le document, ne pas le remettre à false.
      if (aInteragi) {
        state.interactionUsager = true
      }
    },
    setFournisseurRtc(state, fournisseur) {
      console.log('setFournisseurRtc', fournisseur)
      state.fournisseurRtc = fournisseur
    },
  },
  actions: {
    initFournisseurRtc({ commit }) {
      restApiService
        .get('/api/parametres/by-cle?cle=rtc_mode')
        .then((res) => {
          if (res.data.length) {
            commit('setFournisseurRtc', res.data[0])
          } else {
            commit('setFournisseurRtc', { cle: 'rtc_mode', valeur: 'videosdk' })
          }
        })
    },

    updateFournisseurRtc({ commit, state }, valeur) {
      const url = '/api/parametres'
      const body = {
        id: state.fournisseurRtc.id,
        cle: 'rtc_mode',
        valeur: valeur,
      }

      let action
      if (body.id) {
        console.log('PUT mode_rtc', body)
        action = restApiService.put(url, body)
      } else {
        console.log('POST mode_rtc', body)
        action = restApiService.post(url, body)
      }

      action.then((res) => {
        console.log('RESULT mode_rtc', res.data)
        commit('setFournisseurRtc', res.data)
      })
    },
  },
  getters: {
    jwttoken: state => { return state.jwttoken },
    getAppLanguage: state => { return state.appLanguage },
    interactionUsager: state => state.interactionUsager,
    fournisseurRtc: state => state.fournisseurRtc,
  },
})
