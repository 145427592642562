// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import VuePlyr from 'vue-plyr'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './assets/css/main.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueI18n from 'vue-i18n'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import './assets/css/content-styles.css'
import momentTz from 'moment-timezone'

Vue.use(VueI18n)
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
})

Vue.config.productionTip = false

momentTz.tz.setDefault('America/New_York')

// https://github.com/brockpetrie/vue-moment
const moment = require('moment')
// require('moment/locale/fr')

Vue.use(require('vue-moment'), {
  moment,
})

Vue.use(VuePlyr)
Vue.use(CKEditor)
Vue.mixin({
  methods: {
    formaterDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').isValid() ? moment(date).format('YYYY-MM-DD HH:mm') : ' '
    },
  },
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.store = store
}
