<template>
  <div>
    <v-file-input
      v-show="false"
      id="file-input-image"
      v-model="fichier"
      :label="$t('general.televerser_un_fichier')"
      @change="onMajImage()"
    />
    <v-avatar
      class="profilepic mx-auto d-flex v-card--material__avatar elevation-6 rounded-lg"
      width="330"
      height="100"
      tile
    >
      <v-img
        class="profilepic__image"
        :src="imgsrc"
      />
      <div class="profilepic__content">
        <span class="profilepic__icon">
          <v-btn
            color="primary"
            rounded
            small
            @click="onAfficherEditionImage()"
          ><v-icon
            color="white"
            left
          >mdi-pencil</v-icon>{{ $t('general.modifier') }}</v-btn>
          <v-btn
            color="warning"
            rounded
            small
            @click="onSupprimerImage()"
          ><v-icon
            color="white"
            left
          >mdi-trash-can-outline</v-icon>{{ $t('general.supprimer') }}</v-btn></span>
      </div>
    </v-avatar>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      imgsrc: require('@/assets/customer-account.jpg'),
      fichier: {},
      ccMediaImage: {},
    }),
    watch: {
      value: {
        handler (newValue) {
          if (newValue) {
            this.imgsrc = `data:${this.value.dataContentType};base64, ${this.value.data}`
          }
        },
      },
    },
    methods: {
      onAfficherEditionImage() {
        const fileUpload = document.getElementById('file-input-image')
        if (fileUpload != null) {
          fileUpload.click()
        }
      },
      onMajImage () {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          const data = window.btoa(reader.result)
          this.imgsrc = `data:${this.fichier.type};base64, ${data}`
          const m = {
            id: this.value?.id,
            data: data,
            dataContentType: this.fichier.type,
            nom: this.fichier.name,
          }
          this.$emit('input', m)
          this.$emit('majImage', m)
        }
        reader.readAsBinaryString(this.fichier)
      },
      onSupprimerImage () {
        this.$emit('suppImage', this.value)
        this.$emit('input', null)
        this.imgsrc = require('@/assets/customer-account.jpg')
      },
    },
  }

</script>

<style scoped>
.profilepic {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111;
  z-index: 1;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
  z-index: 2;
}

.profilepic:hover .profilepic__image {
  opacity: .5;
}

.profilepic__image {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}
</style>
