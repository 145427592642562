import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store.js'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  fr: {
    ...require('@/locales/fr.json'),
    $vuetify: fr,
  },
}

const dateTimeFormats = {
  // eslint-disable-next-line quote-props
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  // eslint-disable-next-line quote-props
  'fr': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

export default new VueI18n({
  locale: store.getters.getAppLanguage,
  dateTimeFormats,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})
