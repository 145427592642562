export default {
    jouer() {
        const audio = this.getAudio()
        audio.muted = false
        audio.currentTime = 0
        audio.play()
            .then(() => console.log('notificationSonore.jouer - succès'))
            // Va s'afficher à l'affichage, mais à fin d'aider à debugger, on le laisse pour l'instant.
            // Sera à retirer éventuellement pour éviter la pollution dans la console :)
            .catch((err) => console.warn('notificationSonore.jouer - erreur:', err))
    },

    initialiser() {
        window.notificationSonore = this
        if (!this.premiereLecture) {
            const audio = this.getAudio()
            audio.currentTime = 0
            audio.play()
                .then(() => {
                    console.log('notificationSonore.initialiser - succès')
                    audio.muted = true
                    this.premiereLecture = true
                })
                // Va s'afficher à l'affichage, mais à fin d'aider à debugger, on le laisse pour l'instant.
                // Sera à retirer éventuellement pour éviter la pollution dans la console :)
                .catch((err) => console.warn('notificationSonore.initialiser - erreur', err))
        }
    },

    getAudio() {
        if (!this.audio) {
            this.audio = new Audio(require('@/assets/audio/notification-sonore.mp3'))
            this.audio.autoplay = true
        }

        return this.audio
    },

}
